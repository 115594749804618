<template lang="pug">
v-app
  v-container(grid-list-xl, fluid).black
    v-layout(row, wrap, v-if="questoes.length")
      v-flex.md12.md12.mt-4.text-xs-center
        img.imagemLogo(:src="questionario.licenca.logo", alt='Automatic 4', width='150', height="150")
        h2.white--text.font-weight-bold {{ questionario.licenca.nome }}
      v-flex.xs12.md8.offset-xs0.offset-md2
          form(@submit.prevent="save")
            v-stepper(v-model='step', vertical='', :elevation="24").containerPrincipal
              v-stepper-step(step='1' v-bind:complete='step > 1')
                | Informações 
                small Dados básicos da empresa
              v-stepper-content(step='1',)
                form(@keydown.enter.prevent='validate')
                  v-layout(row, wrap)
                    v-flex.md6.xs12
                      v-text-field#razao_social(v-model='diagnostico.razao_social' name='razao_social' label="Razão Social" :error-messages="errors.collect('razao_social')" v-validate="'required'" required='')
                    v-flex.md6.xs12
                      v-text-field#cpf_cnpj(v-model='diagnostico.cpf_cnpj' name='cpf_cnpj' label="CPF ou CNPJ" :error-messages="errors.collect('cpf_cnpj')" v-validate="'required'" v-mask="['XX.XXX.XXX/XXXX-XX', 'XXX.XXX.XXX-XX']" required='')
                  v-layout(row, wrap)
                    v-flex.md12.xs12
                      v-text-field#nome_responsavel(v-model='diagnostico.nome_responsavel' name='nome_responsavel' label="Nome do responsável" :error-messages="errors.collect('nome_responsavel')" v-validate="'required'" required='')
                    //- v-text-field(label="Razão Social", color="primary", required, v-validate="'required'", :error-messages="errors.collect('razao_social')", data-vv-name="razao_social", data-vv-as="Razão social", type="text", v-model='diagnostico.razao_social')
                  v-btn(color='primary' @click.native='step = 2' :disabled='!(diagnostico.razao_social.length >= 4 && diagnostico.cpf_cnpj.length >= 14 && diagnostico.nome_responsavel.length >= 4)') Continuar
                  
              v-stepper-step(step='2' v-bind:complete='step > 2')
                | Contato 
                small Informações de contato
              v-stepper-content(step='2')
                v-layout(row, wrap)
                    v-flex.md12.xs12
                      v-text-field#email(v-model='diagnostico.email' name='email' v-validate="'required|email'" label="E-mail" :error-messages="errors.collect('email')" required='')
                v-layout(row, wrap)
                  v-flex.md12.xs12
                    v-text-field#telefone(v-model='diagnostico.telefone' type="text" name='telefone' v-mask="['(##) ####-####', '(##) #####-####']" label="Telefone ou celular" :error-messages="errors.collect('telefone')" v-validate="'required'" required='')
                v-btn(flat='' @click.native='step = 1') Voltar
                v-btn(color='primary' @click.native='step = 3' :disabled='!(validEmail(diagnostico.email) && diagnostico.telefone.length > 13) ') Continuar
              v-stepper-step(step='3' v-bind:complete='step > 3')
                | Diagnóstico Rápido 
                small Questionário
              v-stepper-content(step='3')
                v-flex(v-if="listQuestoes && listQuestoes.length")
                  v-expansion-panel.elevation-0(v-model='panel', expand)
                    v-expansion-panel-content(v-for='questao in listQuestoes', :key='questao.id')
                      template(v-slot:header='')
                        span.title {{ questao.questao_dr.texto }}
                      v-card
                        v-card-text
                          v-layout(row, wrap)
                            v-radio-group(row)
                              v-flex(v-for='opcao in questao.questao_dr.opcoes_questao_dr', :key='questao.questao_dr.opcoes_questao_dr.id').md12.conteudo
                                v-radio(:value="opcao.id", :label="opcao.texto", @change="selectOption(opcao.id, questao.questao_dr.id, opcao.peso)" color="bt-primary")
                  v-flex(v-if="!listQuestoes || !listQuestoes.length").md12.text-xs-center
                    span.headline Nenhuma informação encontrada!  
                v-btn(color='primary', type='submit' :disabled='!answered' :loading='loading') Finalizar
              v-stepper-step(step='4' v-bind:complete='step == 4') Resultados
              v-stepper-content(step='4')
                v-layout(row, wrap).pa-2
                  v-flex.md12.mt-2.text-xs-center
                    span.title {{ questionario.conteudo_email }}
                  v-flex.md12.mt-2.text-xs-center
                    span.body-2 {{ questionario.apendice_email }}
                v-layout(row, wrap).pa-2
                  v-flex.md12.mt-2.text-xs-center
                    v-btn(color='success', @click="step = 1") Realizar outro DR 

    v-layout(row, wrap, v-else)
      h2 Diagnóstico Rápido não encontrado!

  v-dialog(v-model="dialogDR", width="auto", scrollable, max-width="800px", persistent, v-if="dialogDR")
    v-card
      form(@submit.prevent="salvar('resposta')", data-vv-name="resposta")
        v-card-title.headline.grey.lighten-2
          v-layout(row, wrap)
            v-flex.md11
              span.display-1 {{ questionario.licenca.nome }} - {{ questionario.nome }}
            v-flex.md1.text-xs-right
              v-tooltip(top)
                v-btn(icon, slot="activator", @click.native="dialogDR = false;").pa-0.mx-0.my-0.ma-0
                  v-icon close
                span Fechar
        v-divider
        v-card-text
          v-layout(row, wrap)
            v-flex.md12.mt-3.text-xs-center
              span.headline {{ questionario.conteudo_email }}
            v-flex.md12.mt-3.text-xs-center
              span.headline {{ questionario.apendice_email }}
        v-divider
        v-card-actions.headline.grey.lighten-2
          v-layout(row, wrap)
            v-flex.xs12.text-xs-right
              v-btn(color="black", flat, @click.native="dialogDR = false;") Fechar

</template>

<script>
import _ from "lodash";

export default {
  components: {},
  data: () => ({
    panel: null,
    step: 1,
    answered : false,
    dialogDR: false,
    valid: true,
    e6: 1,
    loading: false,
    questoes : [],
    questao : {},
    questionario : {},
    questionario_id : null,
    diagnostico : {
      razao_social : "",
      nome_responsavel : "",
      telefone : "",
      cpf_cnpj : "",
      email : ""
    },
    listQuestoes : [],
    questao_option : [],
  }),
  computed: {
    list() {
      const vm = this;
      let questoes = _.cloneDeep(vm.questoes);
      return questoes;
    },
  },
  async mounted() {
    const vm = this;
    if (vm.$route.params.uuid) {
      await vm.getQuestionarioId(vm.$route.params);
      if(vm.questionario_id)
        await vm.getQuestionario();
    }
    vm.panel = _.times(vm.questoes.length, _.constant(true));
  },
  methods: {
    validEmail: function (email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async getQuestionario () {
      let vm = this;
      try {
        const response = await vm.$axios.get(`/questionario-dr-public/${vm.questionario_id}`, { params : { fields: "questoes_questionario_dr,licenca"  } });
        vm.questionario = response.data || {};
        vm.listQuestoes = response && response.data && response.data.questoes_questionario_dr ? response.data.questoes_questionario_dr : [];
        _.map(vm.listQuestoes, q => {
          vm.questoes.push(q.questao_dr);
        });
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
      async getQuestionarioId(params) {
      let vm = this;
      try {
        const response = await vm.$axios.get(`/questionario-dr-public-uuid/${params.uuid}`);
        vm.questionario_id = response.data && response.data.id ? response.data.id : null;
      } catch (error) {
        console.log("error get id", error);
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async save () {
      const vm = this;
      vm.loading = true;
      if(!vm.answered) {window.getApp.$emit('APP_ALERT', { color: 'red', text: 'Por favor, responda todas as perguntas.' }); return false; };
      try {
        vm.diagnostico.questionario_dr_id = vm.questionario_id;
        let questoes = [];
        _.map(vm.questao_option, questao => {
            let data = {
                questao_dr_id: questao.questao_id,
                opcao_questao_dr_id: questao.opcao_questao_id,
                peso: questao.peso
            }
            questoes.push(data);
        });
        const responseDiagnostico = await vm.$axios.post('/diagnostico-rapido-public', { diagnostico: vm.diagnostico, questionario: vm.questionario, questoes });
        if (responseDiagnostico && !_.isNil(responseDiagnostico.data)) {
          // vm.dialogDR = true;
          questoes = [];
          vm.questao_option = [];
          window.getApp.$emit('APP_ALERT', { color: 'success', text: `Diagnóstico rápido efetuado com sucesso` });
          vm.loading = false;
          vm.diagnostico = {
            razao_social : "",
            nome_responsavel : "",
            telefone : "",
            cpf_cnpj : "",
            email : "",
          };
          vm.$validator.reset();
          vm.step = 4;
        } else {
          throw false;
        }
      } catch (error) {
        let err = error.response && error.response.data ? error.response.data.error : 'Ocorreu um erro ao buscar os dados. Atualize a página!';
        window.getApp.$emit('APP_ALERT', { color: 'red', text: err });
        vm.loading = false;
      }
    },
    async selectOption(opcao_questao_id, questao_id, peso){
      const vm = this;
      let selecionada = _.find(vm.questao_option, { questao_id : questao_id });
      if(!selecionada) {
        vm.questao_option.push({
            opcao_questao_id,
            questao_id,
            peso
        });
      } else {
        _.find(vm.questao_option, { questao_id : questao_id }).opcao_questao_id = opcao_questao_id;
        _.find(vm.questao_option, { questao_id : questao_id }).peso = peso;
      }
      vm.validateQuestions();
    },
    async validateQuestions(){
      const vm = this;
      let indexesQuestionsSelected = [];
      let indexesQuestions = [];
      _.map(vm.questao_option, q => {
        indexesQuestionsSelected.push({
          questao_id : q.questao_id
        });
      });
      _.map(vm.questoes, q => {
        indexesQuestions.push({
          questao_id : q.id
        });
      });
      if(_.isEqual(indexesQuestionsSelected, indexesQuestions)){
        vm.answered = true;
        return true;
      }
      vm.answered = false;
      return false;
    },
  },
};
</script>

<style scoped>
  .imagemLogo {
    border-radius: 20px;
  }
  .containerPrincipal {
    border-radius: 10px;
  }
</style>
